import clsx from 'clsx';
import isWithinInterval from 'date-fns/isWithinInterval';
import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { REWARD_BANNER_RICH_TEXT_CONSTANTS } from '@/components/storyblok/utils/constants';
import { BannerItemFragment } from '@/services/graphql-storyblok';

export const bannerTextColorMap = {
	'bg-ink': 'text-ice',
	'bg-aquamarine': 'text-hemocyanin',
};

type RewardBannerProps = {
	content: BannerItemFragment['content'];
};

export const RewardBanner: FC<RewardBannerProps> = ({ content, ...rest }) => {
	// Check a storyblok banner_color is defined and set a fallback
	const storyblokStyles = content?.banner_color
		? [
				content.banner_color,
				bannerTextColorMap[content.banner_color as 'bg-ink' | 'bg-aquamarine'],
			]
		: ['bg-aquamarine', 'text-hemocyanin'];

	// Check if banner is within display period if specified. Otherwise just return true.
	const isWithinProvidedDisplayPeriod =
		content?.display_start_date && content.display_end_date
			? isWithinInterval(Date.now(), {
					start: new Date(content.display_start_date),
					end: new Date(content.display_end_date),
				})
			: true;

	return isWithinProvidedDisplayPeriod ? (
		<div
			className={clsx(
				'inline-block w-full p-3 text-center text-sm sm:p-4 sm:text-base md:rounded-b-lg md:text-lg',
				...storyblokStyles
			)}
			role="banner"
			{...rest}
		>
			{render(content?.banner_content, {
				...REWARD_BANNER_RICH_TEXT_CONSTANTS,
			})}
		</div>
	) : null;
};
