import { FC } from 'react';

type SadConstantineProps = {
	height?: number;
	width?: number;
};

export const SadConstantine: FC<SadConstantineProps> = ({
	width = 85,
	height = 85,
}) => (
	<svg
		height={height}
		viewBox="0 0 85 85"
		width={width}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby="sad-constantine-svg"
	>
		<title id="sad-constantine-svg" lang="ja">
			悲しげなたこのイラスト {/* Sad looking octopus */}
		</title>
		<ellipse
			cx="41.6748"
			cy="73.8594"
			rx="31.7718"
			ry="8.66505"
			fill="#4F14A5"
		/>
		<path
			d="M59.9666 62.5922C60.0576 65.1577 61.5824 68.8583 64.154 70.2886C66.4753 71.5826 68.3414 72.1956 68.3414 73.6032C68.3414 75.0108 61.4003 78.6433 54.2544 75.1243C47.1085 71.6053 46.4485 67.2463 45.9706 65.4528C53.7992 60.7987 59.9666 62.5922 59.9666 62.5922Z"
			fill="#FF48D8"
		/>
		<path
			d="M68.4324 58.0968C69.4337 53.3973 76.4204 46.6772 77.2851 37.4598C78.423 25.3591 72.2785 3.85938 42.3293 3.85938C12.3802 3.85938 7.91968 26.0402 7.91968 35.303C7.91968 47.6989 17.0455 54.1011 16.6131 58.5055C24.0549 64.3629 52.1606 71.1964 68.4324 58.0968Z"
			fill="#FF48D8"
		/>
		<path
			d="M24.6694 62.5922C24.5784 65.1577 23.0536 68.8583 20.482 70.2886C18.1607 71.5826 16.2946 72.1956 16.2946 73.6032C16.2946 75.0108 23.2357 78.6433 30.3816 75.1243C37.5275 71.6053 38.1875 67.2463 38.6654 65.4528C30.8595 60.7987 24.6694 62.5922 24.6694 62.5922Z"
			fill="#FF48D8"
		/>
		<path
			d="M50.4083 64.3401C50.4083 64.3401 49.8394 69.3575 48.9746 72.3089C48.1098 75.2603 47.0629 80.2095 41.9424 80.2095C36.822 80.2095 35.6385 74.5565 35.2744 72.5359C34.8875 70.3564 34.3186 63.7953 34.3186 63.7953C34.3186 63.7953 42.2383 56.6892 50.4083 64.3401Z"
			fill="#FF48D8"
		/>
		<path
			d="M68.4778 58.0513C67.8179 63.3638 73.2342 63.5454 72.9384 66.5876C72.6425 69.6298 61.3319 69.6298 58.2824 63.6362C55.2329 57.6426 68.4778 58.0513 68.4778 58.0513Z"
			fill="#FF48D8"
		/>
		<g opacity="0.5">
			<path
				opacity="0.5"
				d="M69.3855 54.2496C71.7671 51.6991 72.4527 48.4745 70.9169 47.0473C69.3811 45.6201 66.2054 46.5306 63.8238 49.0811C61.4423 51.6316 60.7566 54.8562 62.2925 56.2834C63.8283 57.7107 67.004 56.8001 69.3855 54.2496Z"
				fill="#F90DCC"
			/>
		</g>
		<g opacity="0.5">
			<path
				opacity="0.5"
				d="M42.6208 59.9986C43.685 57.6852 41.4176 54.3768 37.5564 52.6091C33.6952 50.8414 29.7023 51.2838 28.6381 53.5973C27.5739 55.9107 29.8413 59.2191 33.7025 60.9868C37.5638 62.7545 41.5566 62.312 42.6208 59.9986Z"
				fill="#F90DCC"
			/>
		</g>
		<path
			d="M16.7042 58.0513C17.3642 63.3638 11.9478 63.5454 12.2209 66.5876C12.494 69.6298 23.8274 69.6298 26.8769 63.6362C29.9264 57.6426 16.7042 58.0513 16.7042 58.0513Z"
			fill="#FF48D8"
		/>
		<path
			d="M40.3494 56.9389C44.849 56.9389 48.4967 53.3001 48.4967 48.8113C48.4967 44.3225 44.849 40.6836 40.3494 40.6836C35.8498 40.6836 32.2021 44.3225 32.2021 48.8113C32.2021 53.3001 35.8498 56.9389 40.3494 56.9389Z"
			fill="white"
		/>
		<path
			d="M39.4391 53.9875C43.2726 53.9875 46.3802 50.8873 46.3802 47.0631C46.3802 43.2388 43.2726 40.1387 39.4391 40.1387C35.6057 40.1387 32.498 43.2388 32.498 47.0631C32.498 50.8873 35.6057 53.9875 39.4391 53.9875Z"
			fill="black"
		/>
		<path
			d="M36.4807 45.9279C37.3479 45.9279 38.051 45.2266 38.051 44.3614C38.051 43.4963 37.3479 42.7949 36.4807 42.7949C35.6134 42.7949 34.9104 43.4963 34.9104 44.3614C34.9104 45.2266 35.6134 45.9279 36.4807 45.9279Z"
			fill="white"
		/>
		<path
			d="M67.8695 46.8327C68.5118 42.8845 65.8141 39.1628 61.844 38.52C57.8739 37.8772 54.1348 40.5568 53.4925 44.505C52.8502 48.4532 55.5479 52.1749 59.518 52.8177C63.4881 53.4604 67.2272 50.7809 67.8695 46.8327Z"
			fill="white"
		/>
		<path
			d="M59.8527 50.4686C63.2714 50.4686 66.0428 47.7039 66.0428 44.2934C66.0428 40.8829 63.2714 38.1182 59.8527 38.1182C56.434 38.1182 53.6626 40.8829 53.6626 44.2934C53.6626 47.7039 56.434 50.4686 59.8527 50.4686Z"
			fill="black"
		/>
		<path
			d="M59.0226 41.7969C59.1494 41.0172 58.6186 40.2825 57.837 40.156C57.0554 40.0294 56.3189 40.5589 56.1921 41.3387C56.0652 42.1184 56.596 42.8531 57.3777 42.9796C58.1593 43.1062 58.8957 42.5767 59.0226 41.7969Z"
			fill="white"
		/>
		<path
			d="M64.7912 32.9649C64.7912 32.9876 64.8367 33.0557 64.8595 33.1011C64.8823 33.1465 64.9278 33.2146 64.9505 33.26C65.0188 33.3735 65.0871 33.4643 65.1781 33.5778C65.3374 33.7822 65.4967 33.9865 65.6788 34.1681C65.8608 34.3497 66.0657 34.5087 66.2705 34.6449C66.4981 34.7811 66.7256 34.9173 66.9532 35.0081L66.976 35.0308C67.2035 35.1216 67.3401 35.3941 67.2491 35.6211C67.158 35.8481 66.8849 35.9844 66.6574 35.8935C66.0429 35.7119 65.4512 35.4395 64.9278 35.0308C64.6774 34.8492 64.4271 34.6222 64.1995 34.3951C64.0857 34.2816 63.9719 34.1454 63.8809 34.0319C63.8354 33.9638 63.7671 33.8957 63.7216 33.8276C63.6761 33.7595 63.6306 33.6913 63.5623 33.6005L63.5395 33.5551C63.3347 33.2373 63.4485 32.8059 63.7671 32.6243C64.0857 32.4427 64.5181 32.5335 64.7002 32.8513C64.7685 32.874 64.7685 32.9194 64.7912 32.9649Z"
			fill="#100030"
		/>
		<path
			d="M34.9786 37.4144C34.9331 37.5279 34.8648 37.6187 34.8193 37.7322C34.7738 37.823 34.7055 37.9139 34.66 38.0047C34.5462 38.1863 34.4324 38.3452 34.2958 38.5041C34.0455 38.822 33.7497 39.1171 33.4083 39.3668C33.0897 39.6166 32.7256 39.8436 32.3614 40.0025C31.9973 40.1614 31.6104 40.275 31.2008 40.3431C30.9505 40.3885 30.7229 40.2296 30.6546 39.9798C30.6091 39.7755 30.7229 39.5485 30.9277 39.4577L30.9732 39.435C31.2691 39.3214 31.5422 39.1625 31.7925 38.9809C32.0428 38.7993 32.2704 38.5949 32.4752 38.3452C32.68 38.1182 32.8394 37.8457 32.9987 37.5733C33.0669 37.4371 33.1352 37.3009 33.1807 37.1647C33.2035 37.0965 33.2262 37.0284 33.249 36.9603C33.2718 36.8922 33.2945 36.8468 33.2945 36.7787C33.3173 36.2792 33.7497 35.8933 34.2503 35.916C34.751 35.9387 35.1379 36.37 35.1151 36.8695C35.1151 36.983 35.0924 37.0965 35.0241 37.1874L34.9786 37.4144Z"
			fill="#100030"
		/>
		<path
			d="M51.8648 57.0296C52.8206 56.5528 54.5957 57.1204 54.2316 57.7107C53.9357 58.2102 53.0254 57.688 52.411 57.8015C51.3641 57.9831 51.5689 58.7323 50.7496 58.7323C50.1579 58.7096 50.9089 57.5064 51.8648 57.0296Z"
			fill="#18122D"
		/>
	</svg>
);
