import { Button } from '@krakentech/coral';
import { FC, KeyboardEvent, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { CleverConstantine } from '@/components/svgs/CleverConstantine';
import { SadConstantine } from '@/components/svgs/SadConstantine';
import { copy } from '@/copy';

type ErrorOverlayProps = {
	/**
	 * Text to display on the button.
	 */
	buttonText?: string;
	/**
	 * Error message to display to the user. It can be a React element.
	 */
	message?: ReactNode;
	onClick: () => void;
	svg?: 'clever' | 'sad';
};

export const ErrorOverlay: FC<ErrorOverlayProps> = ({
	message,
	buttonText,
	onClick,
	svg = 'sad',
}) => {
	return ReactDOM.createPortal(
		<div className="h-full w-full bg-primary">
			<div className="fixed top-0 left-0 z-50 block h-full w-full bg-black opacity-75" />
			<div className="fixed top-0 left-0 z-50 flex h-full w-full flex-col items-center justify-center gap-6 text-center backdrop-blur-xs">
				{svg === 'sad' ? (
					<SadConstantine width={100} height={100} />
				) : (
					<CleverConstantine />
				)}
				<p className="mx-4 text-ice sm:w-1/3">{message}</p>
				<div>
					<Button
						onClick={onClick}
						onKeyDown={(e: KeyboardEvent) => {
							if (e.key === 'Enter') {
								onClick();
							}
						}}
						tabIndex={0}
					>
						{buttonText ?? copy.tryAgain}
					</Button>
				</div>
			</div>
		</div>,
		document.body
	);
};
