import isWithinInterval from 'date-fns/isWithinInterval';
import { FC } from 'react';

import { useDomesticRewardContext } from '@/components/contexts/domesticReward';
import { useStoryblokBannersContext } from '@/components/contexts/storyblokBanners';
import { RewardBanner } from '@/components/storyblok/bloks/banner-components/RewardBanner';

export const ReferralSchemeBanner: FC<{ referralSchemeCode?: string }> = ({
	referralSchemeCode,
}) => {
	const domesticRewardContext = useDomesticRewardContext();

	const storyblokBannersContext = useStoryblokBannersContext();

	const referralSchemeCodeFromPropsOrContext =
		referralSchemeCode ||
		domesticRewardContext.activeDomesticSignupRewardSchemeCode;

	const bannerStory = storyblokBannersContext.bannerStories.find(
		(story) =>
			story?.slug?.toLowerCase() ===
			referralSchemeCodeFromPropsOrContext?.toLowerCase()
	);

	// Check if banner is within display period if specified. Otherwise just return true.
	const isWithinProvidedDisplayPeriod =
		bannerStory?.content?.display_start_date &&
		bannerStory?.content.display_end_date
			? isWithinInterval(Date.now(), {
					start: new Date(bannerStory?.content.display_start_date),
					end: new Date(bannerStory?.content.display_end_date),
				})
			: true;

	return bannerStory?.content && isWithinProvidedDisplayPeriod ? (
		<RewardBanner
			data-testid="reward-banner"
			aria-label="reward banner"
			content={bannerStory?.content}
		/>
	) : null;
};
