import { createContext, useContext } from 'react';

type DomesticRewardContextValue = {
	activeDomesticSignupRewardSchemeCode?: string;
};

export const DomesticRewardContext = createContext<DomesticRewardContextValue>({
	activeDomesticSignupRewardSchemeCode: undefined,
});

export const useDomesticRewardContext = (): DomesticRewardContextValue =>
	useContext(DomesticRewardContext);
